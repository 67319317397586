<template>
	<card v-if="showLabel || showSticker" style="width: fit-content">
		<h2 class="card-title">
			{{ $t('eshop.sticker') }}
		</h2>
		<div class="shipping-label-2">
			<div class="shipping-label-section-1">
				<div>
					<span class="shipping-label-serial-number">{{ reservation.containerSerialNumber }}</span>
					<span
						v-if="reservation.boxSerialNumber && reservation.containerType !== 'Store'"
						class="shipping-label-box">
						<span class="shipping-label-separator">/</span>
						<span v-if="!reservation.isSingleBoxInContainer">{{ (reservation.lockName || "").split('-')[1] || reservation.boxSerialNumber }}</span>
						<i
							v-if="reservation.isSingleBoxInContainer"
							:class="reservation.isBoxPublic ? 'fa fa-city' : 'fa fa-user-secret'" />
					</span>
					<br>
					<span v-if="reservation.boxType && reservation.containerType !== 'Store'" class="shipping-label-box-size">{{ boxType(reservation) }}</span>
				</div>
				<div>
					<span v-if="reservation.containerType === 'SmartKeyBox'" class="shipping-label-right">SMARTBOX</span>
					<span v-if="!reservation.containerId" class="shipping-label-right">ADRESA</span>
					<div
						v-if="reservation.containerId && reservation.containerType !== 'SmartKeyBox' && reservation.containerType !== 'PrivateAddress'"
						class="shipping-label-right">
						<span class="shipping-label-pin">PIN</span>
						<strong>{{ reservation.deliveryCode ? reservation.deliveryCode : null }}</strong>
					</div>
				</div>
			</div>
			<div class="shipping-label-section-2">
				<span v-if="getStateAbbreviation(reservation)" class="shipping-label-region-state-abbr">
					<strong>
						{{ getStateAbbreviation(reservation) }}
					</strong>
				</span>
				<span class="shipping-label-region"><strong>{{ reservation.region }}</strong></span>
				<span class="shipping-label-county">{{ reservation.county }}</span>
			</div>
			<div class="shipping-label-section-3">
				<div class="shipping-label-delivery-point">
					<strong>Výdejní místo</strong>
				</div>
				<div class="shipping-label-delivery-point-address">
					<div v-if="!reservation.containerId">
						Doručení na adresu
					</div>
					<div v-else>
						<span v-if="reservation.street"> {{ reservation.street }}</span><br>
						<span v-if="reservation.city">{{ reservation.city }}</span><br>
						<span v-if="reservation.location">{{ reservation.location }}</span>
					</div>
				</div>
			</div>
			<div class="shipping-label-section-4">
				<div class="shipping-label-reservation-number">
					<strong>Číslo obj.</strong>
				</div>
				<div class="shipping-label-reservation-number-value">
					<span class="reservation-number-value">{{ reservation.reservationNumber }}</span>
					<span
						v-if="reservation.packagesCount && Number(reservation.packagesCount) > 1"
						class="shipping-label-package-count">
						1/{{ reservation.packagesCount }}
					</span>
				</div>
				<div
					class="shipping-label-right">
					<span class="shipping-label-pin">ID</span>
					<strong>{{ reservation.reservationId }}</strong>
				</div>
			</div>
			<div class="shipping-label-section-5">
				<div class="shipping-label-recipient">
					<strong>Příjemce</strong>
				</div>
				<div class="shipping-label-recipient-details">
					<div v-if="displayCustomerName(reservation)">
						{{ getFirstName(reservation) }}<strong>{{ getLastName(reservation) }}</strong>
					</div>
					<div v-if="displayCustomerAddress(reservation)">
						{{ getCustomerAddress(reservation) }}
					</div>
					<div v-if="reservation.phone" class="shipping-label-recipient-details-phone">
						<i class="fa fa-phone" aria-hidden="true" />
						{{ reservation.phone }}
					</div>
				</div>
			</div>
			<div class="shipping-label-section-6">
				<div class="shipping-label-sender">
					<strong>Odesílatel</strong>
					<div v-if="reservation.isPostReceive || reservation.isPostSend && reservation.sender">
						<div>{{ reservation.sender.fullName }}</div>
						<div>{{ reservation.sender.street }}</div>
						<div>{{ reservation.sender.city }}</div>
						<div>{{ reservation.sender.zip }}</div>
					</div>
					<div v-else-if="reservation.eshop">
						<div>{{ reservation.eshop.name }}</div>
						<div>{{ reservation.eshop.addressLine1 }}</div>
						<div>{{ reservation.eshop.addressLine2 }}</div>
						<div>{{ reservation.eshop.link }}</div>
					</div>
				</div>
				<div class="shipping-label-penguinbox">
					<img :alt="title" src="/static/img/StickerLogo.svg">
				</div>
				<div class="shipping-label-qr">
					<qr-code
						:text="qrCode"
						:size="128"
						error-level="L"
						class="qr-code-el" />
				</div>
			</div>
		</div>
		<div class="row my-3">
			<div class="col-6">
				<p-button
					:disabled="!reservation.packagesCount"
					@click="print">
					<i slot="labelRight" class="fa fa-print" />{{ $t('eshop.print') }}
				</p-button>
			</div>
			<div class="col-2">
				<label id="reservationPackagesCount">{{ $t('eshop.reservationPackagesCount') }}</label>
			</div>
			<div class="col-4">
				<input
					v-model.number="reservation.packagesCount"
					aria-labelledby="#reservationPackagesCount"
					class="form-control"
					label-for="reservationPackagesCount"
					type="number"
					@change="packagesCountChanged">
			</div>
		</div>
	</card>
</template>

<script>
import { mapMutations } from 'vuex';
import VueQRCodeComponent from 'vue-qr-generator';

import { convertCoordinatesToRad } from '@/util/container-mixin';
import { addSheetForPrint, cleanSheetForPrint } from '@/modules/eshop/store/mutation-types';
import { BoxType } from '@/modules/eshop/store/enums';
import { appName } from '@/modules/eshop/config';
import { getAbbrState } from '@/util/helpers';

export default {
	name: 'ReservationSticker',
	components: {
		'qr-code': VueQRCodeComponent,
	},
	props: {
		reservation: {
			type: Object,
			default: () => {},
		},
		showSticker: {
			type: Boolean,
			default: false,
		},
		lock: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			qrCode: null,
		};
	},
	computed: {
		showLabel() {
			return !!this.reservation?.deliveryCode || !!this.reservation.isPostRecipient;
		},
		coordinatesExists() {
			return !!this.reservation.latitude && !!this.reservation.longitude;
		},
		latitude() {
			return convertCoordinatesToRad(this.reservation.latitude);
		},
		longitude() {
			return convertCoordinatesToRad(this.reservation.longitude);
		},
		typeCzech() {
			return BoxType.czech(this.reservation.type);
		},
	},
	watch: {
		async reservation() {
			if (this.reservation) {
				const result = await this.$service.qrCode.getData(this.reservation.reservationId, this.reservation.eshopOrderId);

				if (result) {
					this.qrCode = JSON.stringify(result);
				}
			}
		},
	},
	methods: {
		...mapMutations(appName, [cleanSheetForPrint, addSheetForPrint]),
		async packagesCountChanged() {
			const result = await this.$service.qrCode.getData(this.reservation.reservationId, this.reservation.eshopOrderId);

			if (result) {
				result.packageCount = this.reservation.packagesCount;
				this.qrCode = JSON.stringify(result);
			}
		},
		async print() {
			this.cleanSheetForPrint();
			if (!this.reservation.reservationId.toString().startsWith('order')) {
				await this.$service.eshop.updatePackagesCount(this.reservation.reservationId, this.reservation.packagesCount);
			}
			this.reservation.qrCode = this.qrCode;
			this.reservation.lock = this.lock;
			this.addSheetForPrint(this.reservation);
			this.$router.push('/eshop/tisk-stitku');
		},
		getCustomerAddress(reservation) {
			if (reservation.customer) {
				const { street, city, zip } = reservation.customer;
				let resultAddress = '';
				if (street) {
					resultAddress += street.trim();
				}
				if (city) {
					resultAddress += `, ${city.trim()}`;
				}
				if (zip) {
					resultAddress += `, ${zip.trim()}`;
				}

				return resultAddress;
			}
			return '';
		},
		getStateAbbreviation(reservation) {
			const state = reservation?.customer?.state;
			if (state) {
				return getAbbrState(state);
			}
			return null;
		},
		displayCustomerName(reservation) {
			if (reservation.customer) {
				return reservation.customer?.fullName;
			}
			return false;
		},
		getFirstName(reservation) {
			const splitted = reservation.customer.fullName.split(' ');
			if (splitted.length > 1) {
				splitted.pop();
				return splitted.join(' ');
			}
			return reservation.customer.fullName;
		},
		getLastName(reservation) {
			const splitted = reservation.customer.fullName.split(' ');
			if (splitted.length > 1) {
				return ` ${splitted.pop()}`;
			}
			return '';
		},
		displayCustomerAddress(reservation) {
			if (reservation.customer) {
				const { street, county, zip, state } = reservation.customer;
				return !!(street || county || zip || state);
			}
			return '';
		},
		boxType(reservation) {
			return BoxType.czech(reservation.boxType);
		},
	},
};
</script>

<style lang="scss" scoped>
table {
	width: 100%;
}
#printId {
	table {
		th {
			width: 40%;
		}
	}
}

.shipping-label-2 {
	margin-top: 12px;
	border: 3px solid black;
	width: 360px;

	& .shipping-label-section-1 {
		display: flex;
		justify-content: space-between;
	}
	& .shipping-label-section-1,
	& .shipping-label-section-2 {
		border-bottom: 1px solid black;
	}

	& .shipping-label-section-1,
	& .shipping-label-section-2,
	& .shipping-label-section-6 {
		padding: 5px;
	}

	& .shipping-label-section-3,
	& .shipping-label-section-5 {
		display: flex;
		border-bottom: 3px solid black;
	}
	& .shipping-label-section-4{
		display: flex;
		border-bottom: 1px solid black;
		padding-right: 5px;
	}

	& .shipping-label-section-6 {
		display: flex;
	}

	& .shipping-label-serial-number {
		font-size: 22px;
	}

	& .shipping-label-right {
		font-size: 22px;
	}
	& .shipping-label-region,
	& .shipping-label-region-state-abbr {
		font-size: 16px;
	}
	& .shipping-label-county {
		font-size: 15px;
		float: right;
	}
	& .shipping-label-delivery-point,
	& .shipping-label-recipient{
		font-size: 16px;
		max-width: 87px;
        min-width: 87px;
		border-right: 1px solid black;
		padding: 5px;
	}

	& .shipping-label-delivery-point-address,
	& .shipping-label-recipient-details {
		padding: 5px;
		flex-grow: 1;
		font-size: 16px;
		min-width: 0;
	}

	& .shipping-label-reservation-number{
		font-size: 16px;
		max-width: 87px;
        min-width: 87px;
		border-right: 1px solid black;
		padding: 5px;
		line-height: 25px;
	}

	& .shipping-label-reservation-number-value{
		font-size: 19px;
		padding: 5px;
		font-weight: bold;
		line-height: 25px;
		justify-content: space-between;
		display: flex;
		flex-grow: 1;
		min-width: 0;

		& .reservation-number-value{
			min-width: 0;
		}
	}

	& .shipping-label-recipient-details-phone {
		text-align: right;
		margin-top: 10px;
	}

	& .shipping-label-sender {
		flex-grow: 1;
		font-size: 16px;
	}
	& .shipping-label-qr {
		width: 128px;
	}

	& .shipping-label-pin {
		margin-right: 5px;
		font-size: 75%;
	}

	& .shipping-label-id {
		margin-right: 5px;
		font-size: 75%;
	}

	& .shipping-label-box {
		font-size: 22px;
	}

	& .shipping-label-separator {
		margin-left: 5px;
		margin-right: 5px;
	}

	& .shipping-label-box-size {
		font-size: 16px;
		text-transform: uppercase;
	}

	& .shipping-label-qr-empty {
		width: 128px;
		height: 128px;
		text-align: center;
		line-height: 128px;
		border: 2px dashed;
		border-radius: 5px;
		font-size: 16px;
	}

	& .shipping-label-penguinbox {
		writing-mode: vertical-rl;
		width: 25px;
		background-color: black;
		color: white;
		margin-top: -5px;
		margin-bottom: -5px;
		margin-right: 5px;
		padding: 2px;
	}
}
</style>
