import { stringify } from 'qs';
import { isNil, omitBy } from 'lodash';

export const EshopService = (http) => ({
	async getList(page, filter, pageSize = 50, abortController = null) {
		const filterParams = omitBy(filter, isNil);
		const config = {
			signal: abortController?.signal,
			params: { page, pageSize, ...filterParams },
			paramsSerializer: stringify,
		};

		const response = await http.get('/api/EshopReservation/List', config);

		return response.data;
	},

	async getOne(id) {
		const response = await http.get('/api/EshopReservation/Detail', { params: { ReservationId: id } });

		return response.data;
	},

	async newReservation(reservation) {
		const response = await http.post('/api/EshopReservation/NewReservation', reservation);

		return response.data;
	},

	async discardReservation(reservationId) {
		const response = await http.post('/api/EshopReservation/Discard', { reservationId });

		return response.data;
	},

	async collectReservation(reservationId) {
		const response = await http.post('/api/EshopReservation/CollectIt', { reservationId });

		return response.data;
	},

	async reactivateReservation(reservationId) {
		const response = await http.post('/api/EshopReservation/Reactivate', { reservationId });
		return response.data;
	},

	async sellDirectItem(directSale) {
		const response = await http.post('/api/EShopReservation/SellDirectItem', directSale);
		return response.data;
	},

	async updatePackagesCount(reservationId, packagesCount) {
		const response = await http.post('/api/EshopReservation/UpdatePackagesCount', { reservationId, packagesCount: Number(packagesCount) });
		return response.data;
	},

	async markAsCollected(reservationId) {
		const response = await http.post('/api/EshopReservation/MarkAsCollected', { reservationId });
		return response.data;
	},
});
